@import ~../../../node_modules/animate.css/animate.compat.css

/* #region Fonts */
=font-face($family: Arial, $type, $weight:normal, $style:normal, $display:swap)
	@font-face
		font-family: $family
		src: url('./fonts/#{$family}/#{$family}-#{$type}.woff2') format('woff2'), url('./fonts/#{$family}/#{$family}-#{$type}.woff') format('woff'),
		font-weight: $weight
		font-style: $style
		font-display: $display

+font-face('HeroNew', 'Regular', 400)
+font-face('HeroNew', 'Medium', 500)
+font-face('HeroNew', 'SemiBold', 600)
+font-face('HeroNew', 'Bold', 700)
+font-face('HeroNew', 'Super', 900)

$base_font_size: 16px
$font_primary: 500 42px 'HeroNew', sans-serif
$font_secondary: 700 $base_font_size 'HeroNew', sans-serif
$font_other: 400 $base_font_size 'HeroNew', sans-serif
/* #endregion */

/* #region Media */
$sm: 'screen and (max-width: 600px)'
$md: 'screen and (max-width: 960px)'
$lg: 'screen and (max-width: 1680px)'
$xl: 'screen and (max-width: 1920px)'
/* #endregion */

/* #region Palette */
$primary: #AD7D66
$secondary: #323E48
$other: #ffffff
/* #endregion */

/* #region Styles */
*
	outline: none
	scrollbar-width: thin
	scrollbar-color: $primary $other

::-webkit-scrollbar
	width: 12px
	height: 12px
	@media #{$sm}
		width: initial
		height: initial

::-webkit-scrollbar-track
	background: $other

::-webkit-scrollbar-thumb
	background-color: $primary
	border-radius: 0

::-webkit-scrollbar-thumb:hover,
::-webkit-scrollbar-thumb:active
	background: $secondary

body
	margin: 0
	padding: 0
	font: $font_other
	background-color: $other
	color: $secondary
	box-sizing: border-box

a
	cursor: pointer
	user-select: none
	color: $secondary
	transition: color 0.15s ease-in-out
	&:hover
		color: $primary

.nowrap_txt
	display: block
	overflow: hidden
	white-space: nowrap
	text-overflow: ellipsis

.page-wstart
	background: $other
	height: 100%
	position: absolute
	width: 100%
	#pjAcceptCookieBar
		display: none

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
	-webkit-appearance: none
	margin: 0

input[type='number']
	-moz-appearance: textfield

.swiper-container
	box-sizing: border-box
	padding: 0 0.5em

.swiper-pagination
	left: 50% !important
	text-align: center
	margin: 0 auto
	transform-origin: center
	transform: translateX(-50%)

.swiper-button-disabled,
.my-button-disabled
	opacity: 0.5 !important
	&:before
		background-color: none !important

.swiper-button-next:after,
.swiper-container-rtl,
.swiper-button-prev:after
	content: none !important
	outline: 0 !important

.swiper-button-prev,
.swiper-button-next
	img
		width: 100%
		height: auto
		outline: 0 !important

.swiper-button-prev
	left: 0

.swiper-button-next
	right: 0

.grecaptcha-badge
	display: none !important
	z-index: 100000

// Icon-Scroll
.icon-scroll,
.icon-scroll:before
	position: absolute
	left: 50%

.icon-scroll
	width: 20px
	height: 35px
	margin-left: -20px
	top: 50%
	margin-top: -35px
	box-shadow: inset 0 0 0 1px #fff
	border-radius: 25px

.icon-scroll:before
	content: ''
	width: 4px
	height: 4px
	background: #fff
	margin-left: -2px
	bottom: 8px
	border-radius: 2px
	animation-duration: 1.5s
	animation-iteration-count: infinite
	animation-name: scroll
/* #endregion */

/* #region Animations */
@keyframes slideBgY
	0%
		background-position: bottom
	50%
		background-position: top
	100%
		background-position: bottom

@keyframes scroll
	0%
		opacity: 1
	100%
		opacity: 0
		transform: translateY(-20px)
/* #endregion */

#pjAcceptCookieBar .pjAcceptCookieBarBtn
	background: #AD7D66!important
	&:after
		border: 4px solid #AD7D66!important
		background: rgb(173, 125, 102)!important